import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { array } from 'helpers'

import { getRecommendedProductCnstrcAttrs } from 'modules/constructorIO'

import { type Label } from 'typings/graphql'

import { NoData } from 'components/feedback'
import { Button, buttonMessages, type ButtonProps } from 'components/inputs'

import ProductCardDetailed, { type ProductCardDetailedProps } from 'compositions/productCards/ProductCardDetailed/ProductCardDetailed'
import ProductCardDetailedSkeleton from 'compositions/productCards/ProductCardDetailedSkeleton/ProductCardDetailedSkeleton'
import ProductCardSmall, { type ProductCardSmallProps } from 'compositions/productCards/ProductCardSmall/ProductCardSmall'
import ProductCardSmallSkeleton from 'compositions/productCards/ProductCardSmallSkeleton/ProductCardSmallSkeleton'


export type FetchMoreButtonProductListProps = {
  className?: string
  products: ProductFragment.Detailed[]
  buttonProps: ProductCardDetailedProps['buttonProps']
  totalCount: number
  fetchSkeletonsAmount: number
  fetchMoreButtonTitle?: Intl.Message
  fetchMoreButtonStyle?: Extract<ButtonProps['style'], 'primary' | 'secondary'>
  isFetching: boolean
  withDetailedMobileCards?: boolean
  withoutLink?: boolean
  withRetailPrice?: boolean
  excludeLabels?: Label[]
  fetchMore: () => void
  dataAttributes?: Record<string, any>
  recommenderStrategies?: Record<string, string>
  onProductLinkClick?: ProductCardDetailedProps['onProductLinkClick'] | ProductCardSmallProps['onProductLinkClick']
}

const FetchMoreButtonProductList: React.FunctionComponent<FetchMoreButtonProductListProps> = (props) => {
  const {
    className,
    products,
    buttonProps,
    fetchMore,
    totalCount,
    fetchSkeletonsAmount,
    fetchMoreButtonTitle,
    fetchMoreButtonStyle,
    excludeLabels,
    isFetching,
    withDetailedMobileCards,
    withoutLink,
    withRetailPrice,
    dataAttributes,
    onProductLinkClick,
    recommenderStrategies,
  } = props

  const { isMobile } = useDevice()

  if (!isFetching && products && !products.length) {
    return (
      <NoData className={isMobile ? 'mt-48' : 'mt-96'} />
    )
  }

  const MobileProductCard = withDetailedMobileCards
    ? ProductCardDetailed
    : ProductCardSmall

  const MobileProductCardSkeleton = withDetailedMobileCards ? ProductCardDetailedSkeleton : ProductCardSmallSkeleton
  const mobileGridColClass = withDetailedMobileCards ? 'grid-cols-1' : 'grid-cols-2'

  const rootClassName = cx(className, 'grid grid-flow-dense items-stretch gap-16', isMobile ? mobileGridColClass : 'grid-cols-3-center')

  const isFetchMoreButtonVisible = products?.length < totalCount

  return (
    <>
      <div className={rootClassName} role="list" {...dataAttributes}>
        {
          Boolean(products?.length) && (
            products.map((data) => {
              const { uid } = data

              const attributes = getRecommendedProductCnstrcAttrs({
                initialAttributes: { role: 'listitem' },
                recommenderStrategies,
                uid,
              })

              return React.createElement(isMobile ? MobileProductCard : ProductCardDetailed, {
                key: data.id,
                data,
                withoutLink,
                replaceRatingWithRetailPrice: withRetailPrice,
                buttonProps,
                excludeLabels,
                onProductLinkClick: onProductLinkClick,
                dataAttributes: attributes,
              })
            })
          )
        }
        {
          isFetching && (
            array.range(1, fetchSkeletonsAmount).map((index) => (
              isMobile ? (
                <MobileProductCardSkeleton key={index} />
              ) : (
                <ProductCardDetailedSkeleton key={index} />
              )
            ))
          )
        }
      </div>
      {
        isFetchMoreButtonVisible && (
          <div className="mt-32 text-center">
            <Button
              title={fetchMoreButtonTitle || buttonMessages.showMore}
              size={56}
              style={fetchMoreButtonStyle || 'secondary'}
              width={320}
              fullWidthOnMobile
              onClick={fetchMore}
              data-testid="viewMoreButton"
            />
          </div>
        )
      }
    </>
  )
}


export default FetchMoreButtonProductList
