import React from 'react'
import { useDevice } from 'device'
import { useEntry } from 'intersection-observer'

import { useCatalogueId, useCatalogueProducts } from 'modules/products'
import { useUser } from 'modules/user'

import type { Label } from 'typings/graphql'

import { Text, type TextStyle } from 'components/dataDisplay'

import ProductCarousel, { type ProductCarouselProps } from 'compositions/productLists/ProductCarousel/ProductCarousel'


type FeedConfig = {
  metadata: QueueModule.Metadata
  input: Partial<ProductsModule.UseCatalogueProductsProps['variables']['input']>
  excludeLabels: Label[]
}

// workaround to add types checking to value and have defined keys
const asFeedConfig = <T extends Record<string, FeedConfig>>(t: T) => t

const feedConfig = asFeedConfig({
  bestsellers: {
    input: {
      productsFeed: 'BESTSELLERS_MAIN',
      filter: [
        { type: 'CATEGORY', filterValueIdList: [ 'bestsellers' ] },
      ],
    },
    metadata: { placement: 'Bestsellers' },
    excludeLabels: [ 'BESTSELLER' ],
  },
  newArrivals: {
    metadata: {
      placement: 'New arrivals',
    },
    input: {
      productsFeed: 'NEW_ARRIVALS',
      filter: [
        { type: 'CATEGORY', filterValueIdList: [ 'newArrival' ] },
      ],
    },
    excludeLabels: [ 'NEW_ARRIVAL' ],
  },
  topRated: {
    metadata: {
      placement: 'Top rated',
    },
    input: {
      sort: 'RATING_DESC',
    },
    excludeLabels: [ ],
  },
})

export type ProductFeedProps = {
  className?: string
  productsContainerClassName?: string
  id?: string
  title: Intl.Message | string
  titleStyle?: TextStyle
  feed: 'bestsellers' | 'newArrivals' | 'topRated'
  type: 'small' | 'detailed'
  limit?: number
  stickyFooterTriggerRef?: React.RefObject<HTMLDivElement>
  withNotification?: boolean
  withNotificationOnly?: boolean
  withUpgradeFlow?: boolean
  availableForQueue?: boolean
  productIdsToExclude?: number[]
  dataAttributes?: ProductCarouselProps['dataAttributes']
  recommenderStrategies?: ProductCarouselProps['recommenderStrategies']
  onProductLinkClick?: ProductCarouselProps['onProductLinkClick']
  'data-testid'?: string
}

const ProductFeed: React.FunctionComponent<ProductFeedProps> = (props) => {
  const { className, productsContainerClassName, feed, type, title, id, limit = 12, stickyFooterTriggerRef,
    withNotification, withUpgradeFlow, withNotificationOnly, availableForQueue = true, productIdsToExclude, 'data-testid': dataTestId,
    dataAttributes, recommenderStrategies, onProductLinkClick } = props

  const { isMobile } = useDevice()
  const { gender } = useUser()

  const [ ref, entry ] = useEntry({ once: true, observerProps: { rootMargin: '50%' } })
  const skip = !entry?.isIntersecting

  const { input, metadata, excludeLabels } = feedConfig[feed]

  const catalogueName: ProductsModule.CatalogueName = gender === 'MALE' ? 'colognes' : 'perfumes'
  const { catalogueId, isFetching: isFetchingCatalogue } = useCatalogueId({ catalogueName, skip })

  const { data, isFetching: isFetchingProducts } = useCatalogueProducts({
    variables: {
      id: catalogueId,
      input: {
        limit,
        availableForQueue,
        ...input,
      },
    },
    ssr: false,
    skip: skip || !catalogueId,
  })

  const products = data?.products || []
  const filteredProducts = productIdsToExclude?.length > 0 ? products.filter((product) => !productIdsToExclude.includes(product.id)) : products

  const titleStyle = props.titleStyle || (isMobile ? 'h5' : 'h4')

  return (
    <div id={id} className={className} ref={ref} data-testid={dataTestId}>
      <Text
        className="mb-32 text-center"
        message={title}
        style={titleStyle}
        html
      />
      <div ref={stickyFooterTriggerRef} />
      <ProductCarousel
        className={productsContainerClassName}
        desktopType={type}
        products={filteredProducts}
        isFetching={isFetchingCatalogue || isFetchingProducts}
        metadata={metadata}
        excludeLabels={excludeLabels}
        buttonProps={{
          metadata,
          withNotification,
          withUpgradeFlow,
          withNotificationOnly,
        }}
        withoutLink
        dataAttributes={dataAttributes}
        recommenderStrategies={recommenderStrategies}
        onProductLinkClick={onProductLinkClick}
      />
    </div>
  )
}


export default React.memo(ProductFeed)
